.org-btn {
  visibility: hidden;
  padding: 0 8px;
  margin: 0 -8px;
}

tr:hover .org-btn {
  visibility: visible;
}

