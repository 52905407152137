//.logo {
//  background-image: url("../../public/logo_full.png");
//  height: 150px;
//  width: 150px;
//  min-height: 150px;
//  background-size: contain;
//  margin: 0 auto;
//}

.logo {
  background-image: url("../../public/logo_white.png");
  background-size: contain;
  height: 14px;
  margin: 20px auto;
  width: 160px;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  display: block;
}

.ant-layout-sider-children {
  display: flex;
  flex-direction: column;
}

.breadcrumb-header {
  background: none;
  box-shadow: 0 0 3px;
  height: 40px;
  padding: 8px 16px;
}

.ant-menu-item-divider {
  flex: 1
}

.ant-menu-root {
  display: flex;
  flex-direction: column;
  flex: 1;
}

#root > .ant-layout {
  display: flex;
  flex-direction: row;
  height: 100vh;
}

.page-with-sub-header {
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.ant-layout-content {
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.page-content {
  display: flex;
  overflow: hidden;
}

.inst-popup ul {
  max-height: 408px;
  overflow-y: auto;
}