path.year-chart-bar {
  cursor: pointer;
  fill: #36cfc9;
  stroke: #006d75;
  &:hover {
    filter: drop-shadow(0 0 1px rgb(0 0 0 / 0.5));
  }

  &.first {
    fill: #36cfc9;
    stroke: #006d75;
  }

  &.second {
    fill: #334B4A;
    stroke: #006d75;
  }

  &.last {
    fill: #95B1AE;
    stroke: #006d75;
  }

  &.other {
    fill: #9DBAFF;
    stroke: #326dfc;
  }

}

.chart-title {
  text-align: center;
  margin-bottom: 10px;
  font-size: 16px;
  height: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

path.year-chart-bar.selected {
  filter: drop-shadow(0 0 3px rgb(0 0 0 / 0.5)) brightness(80%);
}

.recharts-pie-sector .selected {
  filter: drop-shadow(0 0 7px rgb(0 0 0 / 1)) brightness(60%);
}

.recharts-pie-sector .recharts-sector {
  outline: none;
}

.absolute-pos .recharts-wrapper {
  position: absolute!important;
}

.recharts-legend-item svg {
  margin-top: -2px;
}

.legend {
  padding-left: 0;
  li {
    white-space: nowrap;
    margin-bottom: 3px;
    font-size: 12px;
    .legend-count {
      color: #aaa;
    }
    &.active .legend-circle {
      filter: drop-shadow(0 0 3px rgb(0 0 0 / 1));
    }
    cursor: pointer;
  }
  .title {
    display: inline-block;
    max-width: 75%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: -3px;
  }
  .legend-circle {
    display: inline-block;
    width: 12px;
    height: 12px;
    border-radius: 7px;
    position: relative;
    top: 1px;
  }
}

.recharts-pie-sector {
  cursor: pointer;
  &:hover {
    filter: drop-shadow(0 0 1px rgb(0 0 0 / 0.5));
  }
}


.ant-table-wrapper,
.ant-spin-nested-loading,
.ant-spin-container,
.ant-table,
.ant-table-container {
  display: flex;
  flex-flow: column nowrap;
  flex: 1;
  overflow: hidden;
}

.ant-table-body {
  flex: 1;
  overflow-y: scroll;
}

.ant-table-column-title {
  display: flex;
  flex: 1
}

.col-header {
  display: flex;
  align-items: center;
  gap: 10px;
  flex: 1;
  margin: -10px 0 -10px -10px;
  padding: 10px 0 10px 10px;
}

.ant-layout-content {
  overflow: hidden;
}

.people-publications-table {
  border: solid 1px #ddd;
  border-radius: 8px;
  margin-bottom: 8px;
  .ant-table-body {
    position: absolute;
    top: 48px;
    left: 0;
    bottom: 0;
    right: 0;
  }
}

.people-publications-table .yc {
  padding: 0 2px!important;
  white-space: nowrap;
  .ant-table-column-title {
    justify-content: center;
  }
}

.division-publications-table {
  border: solid 1px #ddd;
  border-radius: 8px;
  margin-bottom: 8px;
  .ant-table-body {
    position: absolute;
    top: 39px;
    left: 0;
    bottom: 0;
    right: 0;
  }
}

.ant-table-container {
  margin-bottom: 10px;
}

.highlight-division {
  background: navy;
  display: inline-block;
  padding: 0 5px;
  color: white;
  border-radius: 4px;
  margin: 1px;
}
