.access-denied-main {
  position: absolute;
  display: flex;
  top:0;
  left:0;
  bottom:0;
  right:0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .logo_dark {
    background-image: url("../../public/logo_dark.png");
    height: 50px;
    width: 250px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 50% 50%;
  }
  .logout {
    position: absolute;
    top: 20px;
    right: 20px;
  }
}


