body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.spacer {
  flex: 1
}

.color-cell {
  border: solid #ddd 1px;
  display: inline-block;
  padding: 2px;
  margin: -6px 0;
  border-radius: 2px;
  > div {
    width: 16px;
    height: 16px;
  }
}

.ant-layout {
  background: none;
}

.loading {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ant-form-item.dashed-line {
  border-bottom: dashed 1px #ddd;
  padding-bottom: 12px;
  margin-bottom: 12px;
}

.ant-form.compact .ant-form-item.dashed-line {
  padding-bottom: 6px;
  margin-bottom: 6px;
}


.ant-form.compact .ant-form-item {
  margin-bottom: 12px;
}

.highlight-author {
  color: #2cb576;
}

.people-publications-table .highlight-author {
  cursor: pointer;
}

.people-publications-table .journal-link {
  cursor: pointer;
  color: #69b1ff
}

.ant-layout-content {
  position: relative;
}
.empty-container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0
}

body > iframe {
  display: none;
}

.ant-layout-header.breadcrumb-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0;
  height: 50px;
  .ant-breadcrumb {
    padding: 16px;
    font-size: 20px;
  }
  img {
    height: 50px;
    padding: 8px 16px;
  }
}

.analytics-page .ant-table-cell {
  font-size: 12px;
}

.years input {
  padding: 4px 4px;
}

.pmid-tag {
  display: inline-block;
  padding: 0 2px;
  background: #ddd;
  white-space: nowrap;
  border-radius: 3px;
}

.np-tag {
  display: inline-block;
  padding: 0 2px;
  background: #62b07d;
  color: white;
  white-space: nowrap;
  border-radius: 3px;
}

.rcr-tag {
  display: inline-block;
  padding: 0 2px;
  background: #5d5d9b;
  color: white;
  white-space: nowrap;
  border-radius: 3px;
}

.citations-tag {
  display: inline-block;
  padding: 0 2px;
  background: #b25bc7;
  color: white;
  white-space: nowrap;
  border-radius: 3px;
}

.hc-tag {
  display: inline-block;
  padding: 0 2px;
  background: #b25bc7;
  color: white;
  white-space: nowrap;
  border-radius: 3px;
}

.pubmed-tag {
  display: inline-block;
  padding: 0 2px;
  background: #ddd;
  white-space: nowrap;
  border-radius: 3px;
}

.v1-tag {
  display: inline-block;
  padding: 0 2px;
  background: #f8f88e;
  white-space: nowrap;
  border: solid 1px #ffcf0d;
  margin: -1px;
  border-radius: 3px;
}

.maybe-tag {
  display: inline-block;
  padding: 0 2px;
  background: #ffb2b2;
  white-space: nowrap;
  border-radius: 3px;
}

.score-tag {
  display: inline-block;
  padding: 0 2px;
  background: #2fb9ff;
  white-space: nowrap;
  border-radius: 3px;
}

.snapshot-tag {
  display: inline-block;
  padding: 0 2px;
  background: #03752b;
  color: white;
  white-space: nowrap;
  border-radius: 3px;
}

.btn-link {
  padding: 0;
  height: auto;
}

.manual-edit-deleted {
  text-decoration: line-through;
  color: #aaa;
}

.manual-edit-suspicious {
  color: #ffba87;
}

.crossed-icon {
  position: relative;
  .inner {
    background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' preserveAspectRatio='none' viewBox='0 0 10 10'><path d='M10 0 L0 10 ' stroke='red' stroke-width='1'/><path d='M0 0 L10 10 ' stroke='red' stroke-width='1'/></svg>") no-repeat center center;
    background-size: 100% 100%, auto;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

.btn-suspicious {
  .ant-btn-icon {
    color: #ff8226;
  }
}

.sub-header {
  padding-bottom: 10px;
  display: flex;
  gap: 10px;
  align-items: center;
}

.ant-tabs {
  overflow: hidden;
  .ant-tabs-content-holder {
    overflow-y: auto;
  }
}

.ant-table-column-title {
  overflow: hidden;
}

.ant-table-cell {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  .allow-wrap {
    white-space: normal;
  }
}

.line-aligned-form-item .ant-form-item-row {
  align-items: baseline;
}

.ant-table-column-sorter {
  display: none;
}

.ant-table-column-sorters {
  min-height: 20px;
}

.ant-table-column-sorters:hover .ant-table-column-sorter {
  display: inline;
}

.ant-modal-header .ant-segmented .ant-segmented-item-selected {
  background-color: #1677ff;
  color: #fff;
}

.ant-modal-header .ant-segmented-thumb {
  background-color: #1677ff!important;
}

.publication-item .report-incorrect {
  opacity: 0;
  transition: opacity 0.4s;
}

.publication-item:hover .report-incorrect {
  opacity: 1;
}

.table-export-wrap {
  display: flex;
  flex-flow: column nowrap;
  flex: 1;
  overflow: hidden;
  position: relative;
}
.table-export-wrap {
  .export-data {
    position: absolute;
    bottom: 15px;
    right: 23px;
    z-index: 10;
    padding: 2px;
    border-radius: 3px;
    font-size: 12px;
    display: none;
    height: 23px;
  }
}

.table-export-wrap:hover .export-data {
  display: block;
}

.project-list {
  counter-reset: item;
  padding-left: 0;
  li {
    margin-top: 10px;
  }

  ol {
    counter-reset: item
  }
  li {
    display: block
  }
  li:before {
    content: counters(item, ".") ". ";
    counter-increment: item
  }
  .removed-project {
    .project-name {
      color: #777;
      text-decoration: line-through;
    }
  }
}

.project-list .report-incorrect .anticon-warning {
  color: #bbb;
}

.project-list li li:hover .report-incorrect .anticon-warning {
  color: #ff4d4f;
}