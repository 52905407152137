.filters {
  .ant-select-selector {
    padding: 2px;
    padding-inline-end: 0!important;
  }
  .tag {
    border-radius: 4px;
    border: solid 1px #ddd;
  }
  .ant-select-selection-overflow {
    gap: 3px;
  }
  .close-tag {
    font-size: 11px;
    padding: 0 4px;
  }
  .ant-checkbox+span {
    padding-inline-start: 5px;
    color: #1677ff;
  }
  .ant-select-arrow {
    pointer-events: auto;
    right: 0;
  }
}