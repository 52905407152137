.input-editable:empty:before{
  content:attr(data-placeholder);
  color:grey;
  position: absolute;
  white-space: pre-wrap;
  line-height: 1.4;
}

.input-editable {
  height: 550px;
  outline: none;
  border: solid 1px #bbb;
  padding: 5px;
  overflow: auto;
  div {
    margin-top: 10px;
    &.error {
      background-color: #ffbac0;
    }
    &.warn {
      background-color: #fff281;
    }
  }
}
